@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import "./variables";
@import "./header";
@import "./footer";
@import "./signup";
@import "./queryengine";
@import "./buttons";
@import "./problem-overview";
@import "./difficulty-badge";
@import "./questions";
@import "./cards";
@import "./alerts";
@import "./quick-filters";
@import "./table";
@import "./carousel";
@import "./modal";
@import "./typography";
@import "./cookie-policy";

::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

.text-blur {
  filter: blur(3px);
}

::selection {
  background-color: $pink;
  color: rgba(255, 255, 255, 100);
}

hr {
  margin: 2rem 0;
  color: inherit;
  background-color: $primary-border;
  opacity: 1;
}

body {
  font-family: "Roboto", sans-serif;
  background-color: $primary-dark-bg;
  color: $white;
  font-size: 16px;
}

.homeview-container {
  display: grid;
  grid-template-columns: 333px 1fr 30fr;
  grid-template-rows: 72px 48px 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "homeview-header homeview-header homeview-header"
    "go-premium-banner go-premium-banner go-premium-banner"
    "questions-holder problem-overview code-editor";
  // 'homeview-footer homeview-footer homeview-footer';
  height: 100vh;
  overflow: hidden;
  animation: expand 0.3s ease-in;
  transform: translateZ(0);
  transition: all 1s ease-in;
}

.homeview-without-premium {
  display: grid;
  grid-template-columns: 333px 1fr 30fr;
  grid-template-rows: 72px 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "homeview-header homeview-header homeview-header"
    "questions-holder problem-overview code-editor";
  // 'homeview-footer homeview-footer homeview-footer';
  height: 100vh;
  overflow: hidden;
  animation: expand 0.3s ease-in;
  transform: translateZ(0);
  transition: all 1s ease-in;
}

@keyframes resize {
  0% {
    grid-template-columns: 333px 1fr 30fr;
  }
  25% {
    grid-template-columns: 250px 1fr 30fr;
  }
  50% {
    grid-template-columns: 166px 1fr 30fr;
  }
  75% {
    grid-template-columns: 84px 1fr 30fr;
  }
  100% {
    grid-template-columns: 72px 1fr 30fr;
  }
}

@keyframes expand {
  100% {
    grid-template-columns: 333px 1fr 30fr;
  }
  75% {
    grid-template-columns: 250px 1fr 30fr;
  }
  50% {
    grid-template-columns: 166px 1fr 30fr;
  }
  25% {
    grid-template-columns: 84px 1fr 30fr;
  }
  0% {
    grid-template-columns: 72px 1fr 30fr;
  }
}

.homeview-container-w-collapsed-sidebar {
  animation: resize 0.3s ease-in;
  transform: translateZ(0);
  grid-template-columns: 72px 1fr 30fr;
}

.homeview-header {
  grid-area: homeview-header;
  display: flex;
  align-items: center;
}

.go-premium-banner {
  grid-area: go-premium-banner;
  background-image: $light-pink-gradient;
  display: flex;
  justify-content: center;
  align-items: center;
}

.homeview-footer {
  grid-area: homeview-footer;
  background-color: $secondary-dark-bg;
}

.questions-holder {
  grid-area: questions-holder;
  padding: 1.5rem;
  border: 1px solid $primary-border;
  border-left: none;
  overflow: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.problem-overview {
  grid-area: problem-overview;
  padding: 1.5rem;
  border: 1px solid $primary-border;
  border-left: none;
  overflow: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  // background-color: $gray-alternate-bg;
}

.code-editor {
  grid-area: code-editor;
  padding: 1.5rem;
  border: 1px solid $primary-border;
  border-left: none;
  overflow: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.custom-handle-style div {
  writing-mode: vertical-rl;
  text-orientation: mixed;
}

.custom-handle-style div:after {
  content: ". . .";
  display: flex;
  flex-direction: column;
  letter-spacing: 4px;
  font-weight: bolder;
  align-items: center;
  justify-content: center;
  position: relative;
  left: 4px;
  color: $white;
}

@media screen and (max-width: 1024px) {
  .homeview-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .homeview-without-premium {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .custom-handle-style div {
    display: none;
  }

  .custom-handle-style div:after {
    display: none;
  }

  .homeview-header {
    height: 100%;
  }

  .go-premium-banner {
    height: 48px;
  }

  .homeview-footer {
    height: 100%;
  }

  .questions-holder {
    height: 100%;
    max-height: 500px;
  }

  .problem-overview {
    height: 100%;
    max-width: 100% !important;
  }

  .code-editor {
    height: 100%;
  }
}
